import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //운송사 정보조회
  getTransComanyList (txtCompany, selectRow) {
    return Send({
      url: '/trans/bookings/trans-company',
      method: 'get',
      params: {
        selectRow: selectRow || '0',
        txtTransCompany: txtCompany
      }
    })
  },
  getPickUpList (formData) {
    return Send({
      url: '/trans/pickup',
      method: 'get',
      params: {
        selectRow: formData.selectRow || '0',
        cntrSz: formData.cntrSize,
        cntrTyp: formData.cntrType,
        polPortCd: formData.polPortCd,
        ctrCd: formData.polCtrCd,
        pickupDate: formData.pickupDate,
        cyCd: formData.cyCd,
        mtyPkupUsagYn: formData.mtyPkupUsagYn,
        iotCntrYn: formData.iotCntrYn
      }
    })
  },
  getBidgChk (cstBidgMgrNo) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/bidding-input',
      params: { cstBidgMgrNo: cstBidgMgrNo }
    })
  },
  getPickupOne (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/booking-pickup-one',
      spinner: false,
      params: {
        pcupCyCd: params.pcupCyCd,
        cntrSzCd: params.cntrSzCd,
        cntrTypCd: params.cntrTypCd,
        polPortCd: params.polPortCd
      }
    })
  },
  getChclAct (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/find-chcl-act',
      params: {
        polPortCd: params.polPortCd,
        etd: params.etd,
        imdgNo: params.imdgNo
      }
    })
  },
  getReeferYnCheck (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/reefer-yn-check',
      params: {
        vslCd: params.vslCd,
        voyNo: params.voyNo,
        polCtrCd: params.polCtrCd,
        hidCS008I: params.hidCS008I,
        scheduleEditYn: params.scheduleEditYn

      }
    })
  },
  getCfsInfo (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/find-cfs-code',
      params: {
        polPortCd: params.polPortCd,
        podPortCd: params.podPortCd,
        podCtrCd: params.podCtrCd,
        vslCd: params.vslCd,
        voyNo: params.voyNo,
        polTrmlCd: params.polTrmlCd,
        arrCntrTypCd: params.arrCntrTypCd,
        conCode: params.conCode,
        findCntrTypYn: params.findCntrTypYn, // CFS 반입지 / 담당자 조회시 Conatiner 목록 조회 여부
        bkgNo: params.bkgNo
      }
    })
  },
  getKmdVslCheck (hidCS008I) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/kmd-vsl-check',
      params: { hidCS008I: hidCS008I }
    })
  },
  getPickUpSet (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/pickup-set',
      params: {
        polCtrCd: params.polCtrCd,
        polPortCd: params.polPortCd,
        cntrSeq: params.cntrSeq || '',
        iotCntrYn: params.iotCntrYn || ''
      }
    })
  },
  getGateInfoSet (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/gate-info-set',
      params: {
        polCtrCd: params.polCtrCd,
        polPortCd: params.polPortCd,
        polTrmlCd: params.polTrmlCd,
        hidCS008I: params.hidCS008I
      }
    })
  },
  getTemplateInfo (bkgNo) {
    return Send({
      method: 'get',
      url: '/trans/bookings/template/' + bkgNo
    })
  },
  getBlPaymentAccountlessList (params) {
    return Send({
      method: 'get',
      url: '/trans/bl-payment/' + params.blNo + '/account-less',
      params: { delegateYn: params.delegateYn }
    })
  },
  getDgMaxCheck (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/dg-max',
      params: {
        cntrSzCd: params.cntrSzCd,
        cntrTypCd: params.cntrTypCd,
        flag: params.flag,
        imdunNo: params.imdunNo,
        imdgSeq: params.imdgSeq,
        dgMatlCd: params.dgMatlCd
      }
    })
  },
  getRtnPhbtChrChk (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/cmdt-phbt-chr',
      params: {
        bkgNo: params.bkgNo,
        etd: params.etd,
        eta: params.eta,
        porCtrCd: params.porCtrCd,
        porPortCd: params.porPortCd,
        polCtrCd: params.polCtrCd,
        polPortCd: params.polPortCd,
        podCtrCd: params.podCtrCd,
        podPortCd: params.podPortCd,
        podTrmlCd: params.podTrmlCd,
        dlyCtrCd: params.dlyCtrCd,
        dlyPortCd: params.dlyPortCd,
        hidCS008I: params.hidCS008I,
        cmdtDsc: params.cmdtDsc,
        scheduleEditYn: params.scheduleEditYn,
        uiFlag: params.uiFlag,
        bkgDt: params.bkgDt || '',
        blNo: params.blNo
      }
    })
  },
  pickupDateRestJp (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/pickup-date-rest-jp',
      params: {
        arrPcupReqDt: params.join(',')
      }
    })
  },
  onDeckLoiDownload (formData) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/file-download',
        method: 'get',
        responseType: 'blob',
        params: {
          messageIdSeq: formData.messageIdSeq,
          fileId: formData.fileId,
          type: 'ON_DECK_LOI'
        }
      }).then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  fileDownload (params) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/file-download',
        method: 'get',
        responseType: 'blob',
        params: params
      }).then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getFwdrInfoList () {
    return Send({
      url: '/trans/bookings/fwdr-list',
      method: 'get'
    })
  },
  findCnclClosePopYn (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/cancel-popup-yn',
      method: 'get'
    })
  },
  findCnclChargeYn (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/cancel-charge-yn',
      method: 'get'
    })
  },
  findFbCatInfo (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/fb-cat-info',
      method: 'get'
    })
  },
  findBkgNoticeInfo (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/find-bkg-notice-info',
      method: 'get'
    })
  },
  fnCancelRequestInfo (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/find-cancel-req-info',
      method: 'get'
    })
  },
  findBkgSplitStatus (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/bkg-split-status',
      method: 'get'
    })
  },
  updateCancelReqDocStatus (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/cancel-req-doc-cancel',
      method: 'put'
    })
  },
  findBkgBlInfo (bkgNo) {
    return Send({
      url: '/trans/bookings/' + bkgNo + '/bkg-bl-no-info',
      method: 'get'
    })
  },
  fnSrBkgInfo (params) {
    return Send({
      url: '/trans/bl/sr-bkg-info',
      method: 'get',
      params: {
        bkgNo: params.bkgNo,
        srNo: params.srNo
      }
    })
  },
  fnBlBkgInfo (params) {
    return Send({
      url: '/trans/bl/' + params.blNo,
      method: 'get',
      params: {
        bkgNo: params.bkgNo
      }
    })
  },
  fnElNoDuplChk (blNo, elNo) {
    return Send({
      url: '/trans/bl/el-no-validation',
      method: 'get',
      params: {
        blNo: blNo,
        elNo: elNo
      }
    })
  },
  getNextPartBlNo (blNo) {
    return Send({
      url: '/trans/bl/' + blNo + '/next-part-bl-no',
      method: 'get'
    })
  },
  fnGetSrNoTemp (params) {
    return Send({
      url: '/trans/bl/sr-no-temp',
      method: 'get',
      params: {
        polPortCd: params.polPortCd,
        porPlcCd: params.porPlcCd
      }
    })
  },
  fnBlCstEnmInfo () {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/bl/bl-cst-enm-info',
        method: 'get'
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  fnSayCheck (params) {
    return Send({
      url: '/trans/bl/say-check',
      method: 'get',
      params: {
        blNo: params.blNo,
        flag: params.flag,
        cnt: params.cnt,
        arrCntrNo: params.arrCntrNo.join(','),
        pkgCd: params.pkgCd
      }
    })
  },
  fnPkgDscrErrCheck (params) {
    return Send({
      url: '/trans/bl/pkg-dscr-err-check',
      method: 'post',
      spinner: false,
      data: params
    })
  },
  findPolEtaEtd (params) {
    return Send({
      url: '/trans/bl/on-board-date-info',
      method: 'get',
      params: {
        bkgNo: params.bkgNo,
        blNo: params.blNo,
        flag: params.flag
      }
    })
  },
  findRFInfo (params) {
    return Send({
      url: '/trans/bl/rf-info',
      method: 'get',
      params: {
        modifyFlag: params.modifyFlag,
        bkgNo: params.bkgNo,
        blNo: params.blNo
      }
    })
  },
  findOOGInfo (params) {
    return Send({
      url: '/trans/bl/oog-info',
      method: 'get',
      params: params
    })
  },
  findDGInfo (params) {
    return Send({
      url: '/trans/bl/dg-info',
      method: 'get',
      params: params
    })
  },
  findHsCd (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/find-hs-cd',
      method: 'get',
      params: {
        ctrCd: params.ctrCd,
        ldCatCd: params.ldCatCd
      }
    })
  },
  saveHsCdMulti (regParams) {
    return Send({
      url: '/trans/bl/save-hs-cd',
      method: 'post',
      data: regParams
    })
  },
  findHsCdCntrNo (params) {
    return Send({
      url: '/trans/bl/find-hs-cntr-no',
      method: 'get',
      params: params
    })
  },
  insertSR (regParams) {
    return Send({
      url: '/trans/bl',
      method: 'post',
      data: regParams
    })
  },
  updateSR (regParams) {
    return Send({
      url: '/trans/bl',
      method: 'put',
      data: regParams
    })
  },
  cancelSR (srNo) {
    return Send({
      url: '/trans/bl/' + srNo + '/cancel-sr',
      method: 'put'
    })
  },
  findSndShaYn (params) {
    return Send({
      url: '/trans/bl/snd-sha-yn',
      method: 'get',
      params: params
    })
  },
  findTopMark () {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/bl/top-mark-list',
        method: 'get'
      }).then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  findTopDscr () {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/bl/top-dscr-list',
        method: 'get'
      }).then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  findVaniInfo (bkgNo) {
    return Send({
      url: '/trans/bl/' + bkgNo + '/vani-info',
      method: 'get'
    })
  },
  findTrmlCompare (params) {
    return Send({
      url: '/trans/bl/trml-compare',
      method: 'get',
      params: {
        kind: params.kind,
        blNo: params.blNo,
        dlyPlcNm: params.dlyPlcNm
      }
    })
  },
  findBlTemplateInfo (blNo) {
    return Send({
      url: '/trans/bl/template-detail-info',
      method: 'get',
      params: {
        blNo: blNo
      }
    })
  },
  findBLErrorCheck (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/find-bl-error-check',
      method: 'get',
      params: {
        kindCd: params.kindCd,
        catCd: params.catCd
      }
    })
  },
  updateErrorCheck (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/error-check-save',
      method: 'put',
      data: {
        blErrorList: params.blErrorList
      }
    })
  },
  updateBLRequest (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/update-bl-request',
      method: 'put',
      data: params
    })
  },
  updateEmlCont (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/update-Eml-Cont',
      method: 'put',
      data: params
    })
  },
  getSlotExchCstNo (vslCd) {
    return Send({
      url: '/trans/bookings/find-slot-exch-cst-no',
      method: 'get',
      params: {
        vslCd: vslCd
      }
    })
  },
  updateBookingStatus (params) {
    return Send({
      url: '/trans/bookings/update-booking-status',
      method: 'put',
      data: params
    })
  },
  findTrdFileInfo (blNo) {
    return Send({
      url: '/trans/bl/trd-file-info',
      method: 'get',
      params: {
        blNo: blNo
      }
    })
  },
  // 세금계산서 Tamplete 등록
  insertTemplateTax (params) {
    return Send({
      method: 'post',
      url: '/trans/bl-payment/tax-templete',
      data: params
    })
  },
  // 세금계산서 Tamplete 수정
  updateTemplateTax (params) {
    return Send({
      method: 'put',
      url: '/trans/bl-payment/tax-templete',
      data: params
    })
  },
  integratedSearch (params) {
    return Send({
      url: '/trans/integrated-search/' + params.keyword,
      method: 'get',
      params: params
    })
  },
  getIranUpload (params) {
    return Send({
      url: '/trans/bookings/' + params.reqRno + '/iran-upload',
      method: 'get',
      params: params
    })
  },
  insertIranUpload (params) {
    return Send({
      url: '/trans/bookings/iran-upload',
      method: 'post',
      data: params
    })
  },
  getPickupInfo (bkgNo) {
    return Send({
      url: '/trans/pickup/' + bkgNo,
      method: 'get'
    })
  },
  editPickupInfo (formData) {
    return Send({
      method: 'put',
      url: '/trans/pickup/edit-pickup/' + formData.bkgNo,
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  getCaRequestInfo (blNo) {
    return Send({
      url: '/trans/bl/ca-request/' + blNo,
      method: 'get'
    })
  },
  getShprVanInfoMulti (arrBkgNoList) {
    return Send({
      url: '/trans/bl/shpr-van-info-multi',
      method: 'get',
      params: {
        arrBkgNoList: arrBkgNoList.join(',')
      }
    })
  },
  containerExcelUpload (param) {
    return Send({
      url: '/trans/bl/cntr-excel-upload',
      method: 'get',
      params: {
        fileId: param.fileId,
        bkgNo: param.bkgNo,
        checkCntr: param.checkCntr
      }
    })
  },
  isDetCombined ({ polCtrCd, polPortCd }) {
    return Send({
      url: '/trans/bookings/is-det-combined',
      method: 'get',
      params: {
        polCtrCd,
        polPortCd
      }
    })
  },
  partSrYn (blNo) {
    return Send({
      url: '/trans/bl/part-sr',
      method: 'get',
      params: {
        blNo: blNo
      }
    })
  },
  getTemplateList (formData) {
    return Send({
      url: '/trans/bookings/template-list',
      method: 'get',
      params: formData
    })
  },
  deleteeTemplate (params) {
    return Send({
      // + formData.bkgNo,
      // api 하위 주소 base url을 제외한 리소스명(URI) 추후 수정
      method: 'post',
      url: '/trans/bookings/template-delete',
      data: params
    })
  },
  findVgmGrsWtChk (params) {
    return Send({
      url: '/trans/bl/vgm-grs-chk',
      method: 'post',
      data: params
    })
  },
  caVgmChk (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/ca-vgm-chk',
      method: 'put',
      data: params
    })
  },
   //엑셀 템플릿 업로드
   hsCdExcelUpload (formData) {
    return Send({
      method: 'post',
      url: '/trans/bl/hscd-excel-upload/' + formData.fileId,
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  // KMTC ON FAQ 다운로드
  lashingPdfDownload (fileName) {
    return Send({
      method: 'get',
      url: '/trans/file-download/static-download/' + fileName,
      responseType: 'blob'
    }).catch((err) => {
      console.log(err)
    })
  },
  findTrdPoaFileInfo (bkgNo, reqCatCd) {
    return Send({
      url: '/trans/sr/trd-poa-file-info',
      method: 'get',
      params: {
        bkgNo: bkgNo,
        reqCatCd: reqCatCd
      }
    })
  },
  checkCntrNoInMy (bkgNo, cntrNo, blNo) {
    blNo = blNo != null ? blNo : ''
    bkgNo = bkgNo != null ? bkgNo : ''

    return Send({
      url: '/trans/bl/check-cntr-no-my',
      method: 'get',
      params: {
        bkgNo: bkgNo,
        cntrNo: cntrNo,
        blNo: blNo
      }
    })
  },
  findOnlyActYn (params) {
    return Send({
      url: '/trans/export/on-going/only_act_yn',
      method: 'get',
      params: {
        blNo: params
      }
    }).catch(err => {
      console.log(err)
    })
  },
  findContainerTareWeight (keyword) {
    return Send({
      url: '/trans/common/search-container-tare-weight/' + keyword,
      method: 'get'
    }).catch(err => {
      console.log(err)
    })
  },
  findVgmDocDtm (blNo) {
    return Send({
      url: '/trans/export/on-going/vgm_doc_dtm',
      method: 'get',
      params: {
        blNo: blNo
      }
    }).catch(err => {
      console.log(err)
    })
  },
  findBlCancelYn (blNo) {
    return Send({
      url: '/trans/bl/find-bl-cancel-yn',
      method: 'get',
      params: {
        blNo: blNo
      }
    }).catch(err => {
      console.log(err)
    })
  }
}
