// 키워드 정의(한/영 구분)
const defKeyword = {
  // ***************************************************** 새로운 부킹
  'booking-new': {
    KR: ['부킹'],
    EN: ['booking']
  },
  // ***************************************************** 스케줄
  // 스케줄 > 구간
  leg: {
    KR: ['스케줄'],
    EN: ['schedule']
  },
  // 스케줄 > 선박
  vessel: {
    KR: ['스케줄'],
    EN: ['schedule']
  },
  // 스케줄 > 기간
  'long-term': {
    KR: ['스케줄'],
    EN: ['schedule']
  },
  // ***************************************************** 진행중인 업무
  // 진행중인 업무 > On Going List
  progress: {
    KR: ['부킹', '비엘', '인보이스', '써티', '발행'],
    EN: ['booking', 'bl', 'b/l', 'invoice', 'certi']
  },
  // 진행중인 업무 > 미해결 업무
  unsolved: {
    KR: ['미반납', '미발행', '미정산', '미반출', '반납', '발행', '정산', '반출'],
    EN: []
  },
  // 진행중인 업무 > 나의 요청사항
  'my-request': {
    KR: ['요청', '부킹', '비엘', '인보이스', '써티', '발행'],
    EN: ['booking', 'bl', 'b/l', 'invoice', 'certi']
  },
  // ***************************************************** 특수화물 선적문의
  // 특수화물 선적문의 > 위험물
  danger: {
    KR: ['위험물', '특수화물'],
    EN: ['dg']
  },
  // 특수화물 선적문의 > OOG
  oog: {
    KR: ['특수화물'],
    EN: ['oog']
  },
  // 특수화물 선적문의 > RF 위험물 사전승인
  specialRfDg: {
    KR: ['리퍼', '냉동', '냉장', '위험물', '특수화물'],
    EN: ['rf', 'reefer', 'dg']
  },
  // ***************************************************** 운임
  // 운임 > 운임문의
  inquiry: {
    KR: ['운임문의'],
    EN: ['freight', 'of']
  },
  // 운임 > 나의 운임
  my: {
    KR: ['운임문의'],
    EN: ['freight', 'of']
  },
  // 운임 > FAK 운임
  fak: {
    KR: ['FAK 운임'],
    EN: ['freight', 'of']
  },
  // ***************************************************** 정산/발행
  // 정산/발행 > 발행
  calcIssueMain: {
    KR: ['정산', '청구', '발행', '입금', '수납', '신용', '한도'],
    EN: []
  },
  // 정산/발행 > Freetime 요청
  FreeTimeRequest: {
    KR: ['프리타임', '디머리지'],
    EN: ['freetime', 'dem', 'demurrage', 'det', 'detention']
  },
  // 정산/발행 > M&R Waiver
  mrWaiver: {
    KR: ['수리비'],
    EN: ['m&r', 'repair']
  },
  // ***************************************************** 화물추적
  'cargo-tracking': {
    KR: ['화물추적', '컨테이너', '위치'],
    EN: ['container', 'tracking']
  },
  // ***************************************************** 나의 정보
  // 나의 정보 > 회원정보
  member: {
    KR: ['회원정보'],
    EN: []
  },
  // 나의 정보 > Report
  report: {
    KR: ['구독', '리포트'],
    EN: ['report']
  },
  // 나의 정보 > Mileage
  mileage: {
    KR: ['마일리지', '적립', '포인트'],
    EN: ['mileage']
  },
  // 나의 정보 > 위수임
  'import-business-agency-information': {
    KR: ['위수임', '위임', '수임', '대행', '대리'],
    EN: []
  },
  // 나의 정보 > 설정 > Profile
  profile: {
    KR: ['프로필', '설정', '회원정보'],
    EN: ['profile']
  },
  // 나의 정보 > 설정 > 스케줄 구독
  'sch-subs': {
    KR: ['프로필', '설정', '회원정보', '구독'],
    EN: ['profile']
  },
  // ***************************************************** 고객센터
  // 고객센터 > 공지사항
  notice: {
    KR: ['고객센터', '공지사항', '노티스'],
    EN: ['cs', 'notice']
  },
  // 고객센터 > Network
  network: {
    KR: ['고객센터', '네트워크', '연락처', '담당자'],
    EN: ['cs', 'network', 'contact']
  },
  // 고객센터 > 자료실
  pds: {
    KR: ['자료실', '파일', '양식', '담당자'],
    EN: []
  },
  // 고객센터 > FAQ
  faq: {
    KR: [],
    EN: []
  },
  // 고객센터 > 고객의 소리
  Voc: {
    KR: ['불만'],
    EN: ['voc']
  },
  // 고객센터 > 정보조회 > Vessel
  'vessel-Info': {
    KR: ['정보', '선박', '배'],
    EN: ['vessel']
  },
  // 고객센터 > 정보조회 > MRN/MSN/Call Sign
  mrnmsn: {
    KR: ['정보', '콜사인', '선박', '배'],
    EN: ['mrn', 'msn', 'call sign', 'vessel']
  },
  // 고객센터 > 정보조회 > Local Charge
  'local-charge': {
    KR: ['로칼차지', '운임문의'],
    EN: ['local charge']
  },
  // 고객센터 > 정보조회 > DEM/DET Tariff
  'dem-det-tariff': {
    KR: ['프리타임', '디머리지', '타리프', '운임문의'],
    EN: ['freetime', 'dem', 'demurrage', 'det', 'detention']
  },
  // 고객센터 > 정보조회 > 환율
  'exchange-rate': {
    KR: ['정보', '환율'],
    EN: ['exchange rate']
  },
  // 고객센터 > 정보조회 > 인터지스 CFS 입고조회
  'intergis-cfs': {
    KR: ['인터지스'],
    EN: ['cfs', 'lcl']
  }
}

// 메뉴 리스트
export const keywords = {
  // 새로운 부킹
  'booking-new': {
    menuName: '새로운 부킹',
    menuPath: '새로운 부킹',
    routerPath: '/booking-new',
    routerName: 'booking-new',
    keyword: {
      KR: [...defKeyword['booking-new'].KR],
      EN: [...defKeyword['booking-new'].EN]
    }
  },
  // 스케줄
  leg: {
    menuName: '스케줄',
    menuPath: '스케줄 > (T) 구간',
    routerPath: '/schedule/leg',
    routerName: 'leg',
    keyword: {
      KR: [...defKeyword.leg.KR],
      EN: [...defKeyword.leg.EN]
    }
  },
  vessel: {
    menuName: '스케줄',
    menuPath: '스케줄 > (T) 선박',
    routerPath: '/schedule/vessel',
    routerName: 'vessel',
    keyword: {
      KR: [...defKeyword.vessel.KR],
      EN: [...defKeyword.vessel.EN]
    }
  },
  'long-term': {
    menuName: '스케줄',
    menuPath: '스케줄 > (T) 기간',
    routerPath: '/schedule/long-term',
    routerName: 'long-term',
    keyword: {
      KR: [...defKeyword['long-term'].KR],
      EN: [...defKeyword['long-term'].EN]
    }
  },
  // 진행중인 업무
  progress: {
    menuName: '진행중인 업무',
    menuPath: '진행중인 업무 > (T) On Going List',
    routerPath: '/working/progress',
    routerName: 'progress',
    keyword: {
      KR: [...defKeyword.progress.KR],
      EN: [...defKeyword.progress.EN]
    }
  },
  unsolved: {
    menuName: '진행중인 업무',
    menuPath: '진행중인 업무 > (T) 미해결 업무',
    routerPath: '/working/unsolved',
    routerName: 'unsolved',
    keyword: {
      KR: [...defKeyword.unsolved.KR],
      EN: [...defKeyword.unsolved.EN]
    }
  },
  'my-request': {
    menuName: '진행중인 업무',
    menuPath: '진행중인 업무 > (T) 나의 요청사항',
    routerPath: '/working/my-request',
    routerName: 'my-request',
    keyword: {
      KR: [...defKeyword['my-request'].KR],
      EN: [...defKeyword['my-request'].EN]
    }
  },
  // 특수화물 선적문의
  danger: {
    menuName: '위험물',
    menuPath: '특수화물 선적문의 > 위험물',
    routerPath: '/dg/danger',
    routerName: 'danger',
    keyword: {
      KR: [...defKeyword.danger.KR],
      EN: [...defKeyword.danger.EN]
    }
  },
  oog: {
    menuName: 'OOG',
    menuPath: '특수화물 선적문의 > OOG',
    routerPath: '/dg/oog',
    routerName: 'oog',
    keyword: {
      KR: [...defKeyword.oog.KR],
      EN: [...defKeyword.oog.EN]
    }
  },
  specialRfDg: {
    menuName: '위험물',
    menuPath: '특수화물 선적문의 > RF 위험물 사전승인',
    routerPath: '/dg/specialRfDg',
    routerName: 'specialRfDg',
    keyword: {
      KR: [...defKeyword.specialRfDg.KR],
      EN: [...defKeyword.specialRfDg.EN]
    }
  },
  // 운임
  inquiry: {
    menuName: '운임문의',
    menuPath: '운임 > (T) 운임문의',
    routerPath: '/shipping-cost/inquiry',
    routerName: 'inquiry',
    keyword: {
      KR: [...defKeyword.inquiry.KR],
      EN: [...defKeyword.inquiry.EN]
    }
  },
  my: {
    menuName: '운임문의',
    menuPath: '운임 > (T) 나의 운임',
    routerPath: '/shipping-cost/my',
    routerName: 'my',
    keyword: {
      KR: [...defKeyword.my.KR],
      EN: [...defKeyword.my.EN]
    }
  },
  fak: {
    menuName: 'FAK 운임',
    menuPath: '운임 > (T) FAK 운임',
    routerPath: '/shipping-cost/fak',
    routerName: 'fak',
    keyword: {
      KR: [...defKeyword.fak.KR],
      EN: [...defKeyword.fak.EN]
    }
  },
  // 정산/발행
  // [TODO]
  // 정산/발행 > (T) 신용거래건 정리
  // 정산/발행 > (T) 정리내역 조회
  // 정산/발행 > (T) 신용한도 조회
  // 정산/발행 > 가상계좌
  // 미구현으로 'topmenu04_06.js' 추가되면 작업해야 함.
  calcIssueMain: {
    menuName: '정산/발행 - 발행',
    menuPath: '정산/발행 > 발행',
    routerPath: '/calcIssue/calcIssueMain',
    routerName: 'calcIssueMain',
    keyword: {
      KR: [...defKeyword.calcIssueMain.KR],
      EN: [...defKeyword.calcIssueMain.EN]
    }
  },
  FreeTimeRequest: {
    menuName: '정산/발행 - Freetime 요청',
    menuPath: '정산/발행 > Freetime 요청',
    routerPath: '/calcIssue/freetime-request',
    routerName: 'FreeTimeRequest',
    keyword: {
      KR: [...defKeyword.FreeTimeRequest.KR],
      EN: [...defKeyword.FreeTimeRequest.EN]
    }
  },
  mrWaiver: {
    menuName: '정산/발행 - M&R Waiver',
    menuPath: '정산/발행 > M&R Waiver',
    routerPath: '/calcIssue/mrWaiver',
    routerName: 'mrWaiver',
    keyword: {
      KR: [...defKeyword.mrWaiver.KR],
      EN: [...defKeyword.mrWaiver.EN]
    }
  },
  // 화물추적
  'cargo-tracking': {
    menuName: '화물추적',
    menuPath: '화물추적',
    routerPath: '/cargo-tracking',
    routerName: 'cargo-tracking',
    keyword: {
      KR: [...defKeyword['cargo-tracking'].KR],
      EN: [...defKeyword['cargo-tracking'].EN]
    }
  },
  // 나의 정보
  member: {
    menuName: '회원정보',
    menuPath: '나의 정보 > 회원정보',
    routerPath: '/my-info/member',
    routerName: 'member',
    keyword: {
      KR: [...defKeyword.member.KR],
      EN: [...defKeyword.member.EN]
    }
  },
  report: {
    menuName: 'Report',
    menuPath: '나의 정보 > Report',
    routerPath: '/my-info/report',
    routerName: 'report',
    keyword: {
      KR: [...defKeyword.report.KR],
      EN: [...defKeyword.report.EN]
    }
  },
  mileage: {
    menuName: 'Mileage',
    menuPath: '나의 정보 > Mileage',
    routerPath: '/my-info/mileage',
    routerName: 'mileage',
    keyword: {
      KR: [...defKeyword.mileage.KR],
      EN: [...defKeyword.mileage.EN]
    }
  },
  'import-business-agency-information': {
    menuName: '위수임',
    menuPath: '나의 정보 > 위수임',
    routerPath: '/my-info/import-business-agency-information',
    routerName: 'import-business-agency-information',
    keyword: {
      KR: [...defKeyword['import-business-agency-information'].KR],
      EN: [...defKeyword['import-business-agency-information'].EN]
    }
  },
  profile: {
    menuName: 'Profile',
    menuPath: '나의 정보 > 설정 > (T) Profile',
    routerPath: '/my-info/setting/profile',
    routerName: 'profile',
    keyword: {
      KR: [...defKeyword.profile.KR],
      EN: [...defKeyword.profile.EN]
    }
  },
  'sch-subs': {
    menuName: '스케줄 구독',
    menuPath: '나의 정보 > 설정 > (T) 스케줄 구독',
    routerPath: '/my-info/setting/sch-subs',
    routerName: 'sch-subs',
    keyword: {
      KR: [...defKeyword['sch-subs'].KR],
      EN: [...defKeyword['sch-subs'].EN]
    }
  },
  // 고객센터
  notice: {
    menuName: '공지사항',
    menuPath: '고객센터 > 공지사항',
    routerPath: '/common/notice',
    routerName: 'notice',
    keyword: {
      KR: [...defKeyword.notice.KR],
      EN: [...defKeyword.notice.EN]
    }
  },
  network: {
    menuName: 'Network',
    menuPath: '고객센터 > Network',
    routerPath: '/common/network',
    routerName: 'network',
    keyword: {
      KR: [...defKeyword.network.KR],
      EN: [...defKeyword.network.EN]
    }
  },
  pds: {
    menuName: '자료실',
    menuPath: '고객센터 > 자료실',
    routerPath: '/common/pds',
    routerName: 'pds',
    keyword: {
      KR: [
        ...new Set([
          ...defKeyword['booking-new'].KR,
          ...defKeyword.leg.KR,
          ...defKeyword.vessel.KR,
          ...defKeyword['long-term'].KR,
          ...defKeyword.progress.KR,
          ...defKeyword.unsolved.KR,
          ...defKeyword['my-request'].KR,
          ...defKeyword.danger.KR,
          ...defKeyword.oog.KR,
          ...defKeyword.specialRfDg.KR,
          ...defKeyword.inquiry.KR,
          ...defKeyword.my.KR,
          ...defKeyword.FreeTimeRequest.KR,
          ...defKeyword.mrWaiver.KR,
          ...defKeyword['cargo-tracking'].KR,
          ...defKeyword.member.KR,
          ...defKeyword.report.KR,
          ...defKeyword.mileage.KR,
          ...defKeyword['import-business-agency-information'].KR,
          ...defKeyword.profile.KR,
          ...defKeyword['sch-subs'].KR,
          ...defKeyword.notice.KR,
          ...defKeyword.network.KR,
          ...defKeyword.pds.KR,
          ...defKeyword.faq.KR,
          ...defKeyword.Voc.KR,
          ...defKeyword['vessel-Info'].KR,
          ...defKeyword.mrnmsn.KR,
          ...defKeyword['local-charge'].KR,
          ...defKeyword['dem-det-tariff'].KR,
          ...defKeyword['exchange-rate'].KR,
          ...defKeyword['intergis-cfs'].KR
        ])
      ],
      EN: [
        ...new Set([
          ...defKeyword['booking-new'].EN,
          ...defKeyword.leg.EN,
          ...defKeyword.vessel.EN,
          ...defKeyword['long-term'].EN,
          ...defKeyword.progress.EN,
          ...defKeyword.unsolved.EN,
          ...defKeyword['my-request'].EN,
          ...defKeyword.danger.EN,
          ...defKeyword.oog.EN,
          ...defKeyword.specialRfDg.EN,
          ...defKeyword.inquiry.EN,
          ...defKeyword.my.EN,
          ...defKeyword.FreeTimeRequest.EN,
          ...defKeyword.mrWaiver.EN,
          ...defKeyword['cargo-tracking'].EN,
          ...defKeyword.member.EN,
          ...defKeyword.report.EN,
          ...defKeyword.mileage.EN,
          ...defKeyword['import-business-agency-information'].EN,
          ...defKeyword.profile.EN,
          ...defKeyword['sch-subs'].EN,
          ...defKeyword.notice.EN,
          ...defKeyword.network.EN,
          ...defKeyword.pds.EN,
          ...defKeyword.faq.EN,
          ...defKeyword.Voc.EN,
          ...defKeyword['vessel-Info'].EN,
          ...defKeyword.mrnmsn.EN,
          ...defKeyword['local-charge'].EN,
          ...defKeyword['dem-det-tariff'].EN,
          ...defKeyword['exchange-rate'].EN,
          ...defKeyword['intergis-cfs'].EN
        ])
      ]
    }
  },
  faq: {
    menuName: 'FAQ',
    menuPath: '고객센터 > FAQ',
    routerPath: '/common/faq',
    routerName: 'faq',
    keyword: {
      KR: [
        ...new Set([
          ...defKeyword['booking-new'].KR,
          ...defKeyword.leg.KR,
          ...defKeyword.vessel.KR,
          ...defKeyword['long-term'].KR,
          ...defKeyword.progress.KR,
          ...defKeyword.unsolved.KR,
          ...defKeyword['my-request'].KR,
          ...defKeyword.danger.KR,
          ...defKeyword.oog.KR,
          ...defKeyword.specialRfDg.KR,
          ...defKeyword.inquiry.KR,
          ...defKeyword.my.KR,
          ...defKeyword.FreeTimeRequest.KR,
          ...defKeyword.mrWaiver.KR,
          ...defKeyword['cargo-tracking'].KR,
          ...defKeyword.member.KR,
          ...defKeyword.report.KR,
          ...defKeyword.mileage.KR,
          ...defKeyword['import-business-agency-information'].KR,
          ...defKeyword.profile.KR,
          ...defKeyword['sch-subs'].KR,
          ...defKeyword.notice.KR,
          ...defKeyword.network.KR,
          ...defKeyword.pds.KR,
          ...defKeyword.faq.KR,
          ...defKeyword.Voc.KR,
          ...defKeyword['vessel-Info'].KR,
          ...defKeyword.mrnmsn.KR,
          ...defKeyword['local-charge'].KR,
          ...defKeyword['dem-det-tariff'].KR,
          ...defKeyword['exchange-rate'].KR,
          ...defKeyword['intergis-cfs'].KR
        ])
      ],
      EN: [
        ...new Set([
          ...defKeyword['booking-new'].EN,
          ...defKeyword.leg.EN,
          ...defKeyword.vessel.EN,
          ...defKeyword['long-term'].EN,
          ...defKeyword.progress.EN,
          ...defKeyword.unsolved.EN,
          ...defKeyword['my-request'].EN,
          ...defKeyword.danger.EN,
          ...defKeyword.oog.EN,
          ...defKeyword.specialRfDg.EN,
          ...defKeyword.inquiry.EN,
          ...defKeyword.my.EN,
          ...defKeyword.FreeTimeRequest.EN,
          ...defKeyword.mrWaiver.EN,
          ...defKeyword['cargo-tracking'].EN,
          ...defKeyword.member.EN,
          ...defKeyword.report.EN,
          ...defKeyword.mileage.EN,
          ...defKeyword['import-business-agency-information'].EN,
          ...defKeyword.profile.EN,
          ...defKeyword['sch-subs'].EN,
          ...defKeyword.notice.EN,
          ...defKeyword.network.EN,
          ...defKeyword.pds.EN,
          ...defKeyword.faq.EN,
          ...defKeyword.Voc.EN,
          ...defKeyword['vessel-Info'].EN,
          ...defKeyword.mrnmsn.EN,
          ...defKeyword['local-charge'].EN,
          ...defKeyword['dem-det-tariff'].EN,
          ...defKeyword['exchange-rate'].EN,
          ...defKeyword['intergis-cfs'].EN
        ])
      ]
    }
  },
  Voc: {
    menuName: '고객의 소리',
    menuPath: '고객센터 > 고객의 소리',
    routerPath: '/common/voc',
    routerName: 'Voc',
    keyword: {
      KR: [
        ...new Set([
          ...defKeyword['booking-new'].KR,
          ...defKeyword.leg.KR,
          ...defKeyword.vessel.KR,
          ...defKeyword['long-term'].KR,
          ...defKeyword.progress.KR,
          ...defKeyword.unsolved.KR,
          ...defKeyword['my-request'].KR,
          ...defKeyword.danger.KR,
          ...defKeyword.oog.KR,
          ...defKeyword.specialRfDg.KR,
          ...defKeyword.inquiry.KR,
          ...defKeyword.my.KR,
          ...defKeyword.FreeTimeRequest.KR,
          ...defKeyword.mrWaiver.KR,
          ...defKeyword['cargo-tracking'].KR,
          ...defKeyword.member.KR,
          ...defKeyword.report.KR,
          ...defKeyword.mileage.KR,
          ...defKeyword['import-business-agency-information'].KR,
          ...defKeyword.profile.KR,
          ...defKeyword['sch-subs'].KR,
          ...defKeyword.notice.KR,
          ...defKeyword.network.KR,
          ...defKeyword.pds.KR,
          ...defKeyword.faq.KR,
          ...defKeyword.Voc.KR,
          ...defKeyword['vessel-Info'].KR,
          ...defKeyword.mrnmsn.KR,
          ...defKeyword['local-charge'].KR,
          ...defKeyword['dem-det-tariff'].KR,
          ...defKeyword['exchange-rate'].KR,
          ...defKeyword['intergis-cfs'].KR
        ])
      ],
      EN: [
        ...new Set([
          ...defKeyword['booking-new'].EN,
          ...defKeyword.leg.EN,
          ...defKeyword.vessel.EN,
          ...defKeyword['long-term'].EN,
          ...defKeyword.progress.EN,
          ...defKeyword.unsolved.EN,
          ...defKeyword['my-request'].EN,
          ...defKeyword.danger.EN,
          ...defKeyword.oog.EN,
          ...defKeyword.specialRfDg.EN,
          ...defKeyword.inquiry.EN,
          ...defKeyword.my.EN,
          ...defKeyword.FreeTimeRequest.EN,
          ...defKeyword.mrWaiver.EN,
          ...defKeyword['cargo-tracking'].EN,
          ...defKeyword.member.EN,
          ...defKeyword.report.EN,
          ...defKeyword.mileage.EN,
          ...defKeyword['import-business-agency-information'].EN,
          ...defKeyword.profile.EN,
          ...defKeyword['sch-subs'].EN,
          ...defKeyword.notice.EN,
          ...defKeyword.network.EN,
          ...defKeyword.pds.EN,
          ...defKeyword.faq.EN,
          ...defKeyword.Voc.EN,
          ...defKeyword['vessel-Info'].EN,
          ...defKeyword.mrnmsn.EN,
          ...defKeyword['local-charge'].EN,
          ...defKeyword['dem-det-tariff'].EN,
          ...defKeyword['exchange-rate'].EN,
          ...defKeyword['intergis-cfs'].EN
        ])
      ]
    }
  },
  'vessel-Info': {
    menuName: 'Vessel',
    menuPath: '고객센터 > 정보조회 > Vessel',
    routerPath: '/common/info/vessel-Info',
    routerName: 'vessel-Info',
    keyword: {
      KR: [...defKeyword['vessel-Info'].KR],
      EN: [...defKeyword['vessel-Info'].EN]
    }
  },
  mrnmsn: {
    menuName: 'MRN/MSN/Call Sign',
    menuPath: '고객센터 > 정보조회 > MRN/MSN/Call Sign',
    routerPath: '/common/info/mrnmsn',
    routerName: 'mrnmsn',
    keyword: {
      KR: [...defKeyword.mrnmsn.KR],
      EN: [...defKeyword.mrnmsn.EN]
    }
  },
  'local-charge': {
    menuName: 'Local Charge',
    menuPath: '고객센터 > 정보조회 > Local Charge',
    routerPath: '/common/info/local-charge',
    routerName: 'local-charge',
    keyword: {
      KR: [...defKeyword['local-charge'].KR],
      EN: [...defKeyword['local-charge'].EN]
    }
  },
  'dem-det-tariff': {
    menuName: 'DEM/DET Tariff',
    menuPath: '고객센터 > 정보조회 > DEM/DET Tariff',
    routerPath: '/common/info/dem-det-tariff',
    routerName: 'dem-det-tariff',
    keyword: {
      KR: [...defKeyword['dem-det-tariff'].KR],
      EN: [...defKeyword['dem-det-tariff'].EN]
    }
  },
  'exchange-rate': {
    menuName: '환율',
    menuPath: '고객센터 > 정보조회 > 환율',
    routerPath: '/common/info/exchange-rate',
    routerName: 'exchange-rate',
    keyword: {
      KR: [...defKeyword['exchange-rate'].KR],
      EN: [...defKeyword['exchange-rate'].EN]
    }
  },
  'intergis-cfs': {
    menuName: '인터지스 CFS 입고조회',
    menuPath: '고객센터 > 정보조회 > 인터지스 CFS 입고조회',
    routerPath: '/common/info/intergis-cfs',
    routerName: 'intergis-cfs',
    keyword: {
      KR: [...defKeyword['intergis-cfs'].KR],
      EN: [...defKeyword['intergis-cfs'].EN]
    }
  }
}
